import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";
import ClientDataService from "../services/client.service";

export default class AddClient extends Component {
  constructor(props) {
    super(props);
    this.onChangeNasname = this.onChangeNasname.bind(this);
    this.onChangeNasid = this.onChangeNasid.bind(this);
    this.onChangePorts = this.onChangePorts.bind(this);
    this.onChangeSecret = this.onChangeSecret.bind(this);
    this.onChangeShortname = this.onChangeShortname.bind(this);


    this.saveTutorial = this.saveTutorial.bind(this);
    this.newTutorial = this.newTutorial.bind(this);

    this.state = {
      submitted : false,
      id: null,
      nasname: "",
      ports: "",
      secret: "",
      nasid: "",
      shortname: ""
    };
  }

  onChangeNasname(e) {
    this.setState({
      nasname: e.target.value
    });
  }

  onChangeNasid(e) {
    this.setState({
      nasid: e.target.value
    });
  }


  onChangePorts(e) {
    this.setState({
      ports: e.target.value
    });
  }

  onChangeSecret(e) {
    this.setState({
      secret: e.target.value
    });
  }

  onChangeShortname(e) {
    this.setState({
      shortname: e.target.value
    });
  }



  saveTutorial() {
    var data = {
      nasname: this.state.nasname,
      nasid: this.state.nasid,
      ports : this.state.ports,
      secret : this.state.secret,
      shortname : this.state.shortname,
    };

    ClientDataService.create(data)
      .then(response => {
        this.setState({
          id: response.data.id,
          nasname: response.data.nasname,
          ports : response.data.ports,
          nasid: response.data.nasid,
          secret: response.data.secret,
          shortname : response.data.shortname
        });
        console.log(response.data);
        this.setState({submitted : true})
      })
      .catch(e => {
        console.log(e);
      });
  }

  newTutorial() {
    this.setState({
      submitted: false,
      id: null,
      username: "",
      value: "",
      location: ""
    });
  }

  render() {
    return (
      <div className="submit-form">
        {this.state.submitted ? (
          <div>
            <h4>Client added successfully!</h4>
            <button className="btn btn-success" onClick={this.newTutorial}>
              Add
            </button>
          </div>
        ) : (
          <div>
            <div className="form-group">
              <label htmlFor="nasname">nasname</label>
              <input
                type="text"
                className="form-control"
                id="nasname"
                required
                value={this.state.nasname}
                onChange={this.onChangeNasname}
                name="nasname"
              />
            </div>

            <div className="form-group">
              <label htmlFor="nasid">nasid</label>
              <input
                type="text"
                className="form-control"
                id="nasid"
                required
                value={this.state.nasid}
                onChange={this.onChangeNasid}
                name="nasid"
              />
            </div>

            <div className="form-group">
              <label htmlFor="ports">ports</label>
              <input
                type="text"
                className="form-control"
                id="ports"
                required
                value={this.state.ports}
                onChange={this.onChangePorts}
                name="ports"
              />
            </div>
            <div className="form-group">
              <label htmlFor="secret">secret</label>
              <input
                type="text"
                className="form-control"
                id="secret"
                required
                value={this.state.secret}
                onChange={this.onChangeSecret}
                name="secret"
              />
            </div>
            <div className="form-group">
              <label htmlFor="shortname">shortname</label>
              <input
                type="text"
                className="form-control"
                id="shortname"
                required
                value={this.state.shortname}
                onChange={this.onChangeShortname}
                name="shortname"
              />
            </div>

            <button onClick={this.saveTutorial} className="btn btn-success">
              Submit
            </button>
          </div>
        )}
      </div>
    );
  }
}
