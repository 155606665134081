import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";

export default class AddTutorial extends Component {
  constructor(props) {
    super(props);
    this.onChangeusername = this.onChangeusername.bind(this);
    this.onChangevalue = this.onChangevalue.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);

    this.saveTutorial = this.saveTutorial.bind(this);
    this.newTutorial = this.newTutorial.bind(this);

    this.state = {
      id: null,
      username: "",
      value: "", 
      location: ""
    };
  }

  onChangeusername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangevalue(e) {
    this.setState({
      value: e.target.value
    });
  }

  onChangeLocation(e) {
    this.setState({
      location: e.target.value
    });
  }


  saveTutorial() {
    var data = {
      username: this.state.username,
      value: this.state.value,
      location : this.state.location
    };

    TutorialDataService.create(data)
      .then(response => {
        this.setState({
          id: response.data.id,
          username: response.data.username,
          value: response.data.value,
          location: response.data.location
        });
        console.log(response.data);
        this.setState({submitted : true})
      })
      .catch(e => {
        console.log(e);
      });
  }

  newTutorial() {
    this.setState({
      submitted: false,
      id: null,
      username: "",
      value: "",
      location: ""
    });
  }

  render() {
    return (
      <div className="submit-form">
        {this.state.submitted ? (
          <div>
            <h4>User added successfully!</h4>
            <button className="btn btn-success" onClick={this.newTutorial}>
              Add
            </button>
          </div>
        ) : (
          <div>
            <div className="form-group">
              <label htmlFor="username">username</label>
              <input
                type="text"
                className="form-control"
                id="username"
                required
                value={this.state.username}
                onChange={this.onChangeusername}
                name="username"
              />
            </div>

            <div className="form-group">
              <label htmlFor="value">value</label>
              <input
                type="text"
                className="form-control"
                id="value"
                required
                value={this.state.value}
                onChange={this.onChangevalue}
                name="value"
              />
            </div>

            <div className="form-group">
              <label htmlFor="value">Vendor</label>
              <input
                type="text"
                className="form-control"
                id="location"
                required
                value={this.state.location}
                onChange={this.onChangeLocation}
                name="location"
              />
            </div>

            <button onClick={this.saveTutorial} className="btn btn-success">
              Submit
            </button>
          </div>
        )}
      </div>
    );
  }
}
